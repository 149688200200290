import React, { useEffect, useRef, useState } from "react";
// import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import { data, options } from "./chart_setting";
import config from "../../../config/config";
import api from "../../../config/api";
import "./HeaderChart.css";
import { connect } from "react-redux";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import GreaterSignIcon from "../../../assets/images/Data/greaterSign.svg";
import LessSignIcon from "../../../assets/images/Data/lessSign.svg";
import CalendarIcon from "../../../assets/images/Data/calendar.svg";
import { getBillingInvoice } from "../../../store/actions/billingAction";
import { abbreviateNumber } from "js-abbreviation-number";

const HeaderChart = React.memo((props) => {
  const [highestAmount, setHighesAmount] = useState(0);
  const [rc, setRc] = useState(false);
  const [monthNames, setMonthNames] = useState([]);
  const isDesktop = useMediaQuery({ minWidth: 1268 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { previosCalendar, setPreviousCalendar } = props;

  const { GET_TOKEN } = config.api_endpoint;
  useEffect(() => {
    if (props.billing.invoices && props.billing.past_invoices) {
      props.setLoading(true);
      let cloneMonthNames = [];
      if (props.billing.invoices.length > 0) {

        let currentInvoices = props.billing.invoices.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        let pastInvoices = props.billing.past_invoices.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

        for (let x = 0; x < currentInvoices.length; x++) { 
          let firstObj = pastInvoices[x];
          let secondObj = currentInvoices[x];
          console.log("Compare: " + firstObj.timestamp + ", " + secondObj.timestamp);
          cloneMonthNames.push({
            ...secondObj,
            uv: !secondObj.amount || secondObj.amount == "-" ? 0: secondObj.amount,
            pv: !firstObj.amount || firstObj.amount == "-" ? 0: firstObj.amount,
            title: moment(secondObj.timestamp).format("MMM"),
            name: moment(secondObj.timestamp).format("YYYY"),
            name2: moment(firstObj.timestamp).format("YYYY"),
            label: moment(secondObj.timestamp).format("MMM YYYY"),
            amount2: firstObj.amount,
          });
        }
      }

      // props.billing.invoices.map((d) => {
      //   let formatMon = moment(d.timestamp).format("MMMM");

      //   if (d.amount) {
      //     cloneMonthNames.push({
      //       ...d,
      //       uv: d.amount == "-" ? 0 : d.amount,
      //       pv: d.amount == "-" ? 0 : d.amount - 200,
      //       name: moment(d.timestamp).format("MMM YYYY"),
      //     });
      //     // cloneMonthNames[search].uv = d.amount;
      //   } else {
      //     cloneMonthNames.push({
      //       ...d,
      //       uv: 0,
      //       pv: 0,
      //       name: moment(d.timestamp).format("MMM YYYY"),
      //     });
      //     // cloneMonthNames[search].uv = 0;
      //   }
      // });
      let result = cloneMonthNames.map((y) => y.uv);
      let maxValue = Math.max.apply(null, result);
      setHighesAmount(maxValue);

      // cloneMonthNames.map((c) => {
      //   c.pv = "100%";
      // });
      cloneMonthNames.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );
      console.log("maxValue", maxValue);
      console.log("cloneMonthNames:", cloneMonthNames);
      setMonthNames(cloneMonthNames);
      setRc(!rc);
      props.setLoading(false);
    }
  }, [props.billing.invoices]);

  useEffect(() => {
    if (isMobile) {
      const from_date = moment(previosCalendar)
        .subtract(5, "months")
        .startOf("months")
        .format("YYYY-MM-DD");

      const to_date = moment(previosCalendar)
        .subtract(1, "months")
        .endOf("months")
        .format("YYYY-MM-DD");
      props.getBillingInvoice(isMobile, from_date, to_date);
    } else {
      props.getBillingInvoice();
    }
  }, [previosCalendar, isMobile]);

  const downloadPdf = (url, name) => {
    api.get(GET_TOKEN).then((res) => {
      let combineLink = url + res.results.token;

      props.setPdfFile({
        url: combineLink,
        filename: name,
      });
    });
  };

  const label = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formatter = (value) =>
    `${abbreviateNumber(value, 0).toLocaleString()}`;
  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  var tooltip;

  const CustomTooltip = ({ active, payload }) => {
    if (!active || !tooltip) return null;
    for (const bar of payload)
      if (bar.dataKey === tooltip) {
        return (
          <div
            style={{
              background: "rgba(0,0,0,.8)",
              borderRadius: 8,
              color: "white",
              fontWeight: "bold",
              fontSize: 11,
              padding: 8,
              minWidth: 50,
            }}
          >
            <div>
              <div>{bar.payload.title}</div>
              <div>{bar.payload.name2} : {
                bar.payload.amount2 == "-" || bar.payload.amount2 == 0
                ? "0"
                : `${Number(bar.payload.amount2).toLocaleString()}`
              }</div>
              <div>{bar.payload.name} : {
                bar.payload.amount == "-" || bar.payload.amount == 0
                ? "0"
                : `${Number(bar.payload.amount).toLocaleString()}`
              }</div>
            </div>
            {/* <div>{_name}</div>
            {_amount == "-" || _amount == 0
              ? "$0"
              : `$${Number(bar.value).toLocaleString()}`} */}
          </div>
        );
      }

    return null;
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text dy={16} textAnchor="middle" fill="#666" si>
          {payload.value}
        </text>
      </g>
    );
  };
  return (
    <div className={`header_chart_container ${theme}`}>
      <div
        className="title"
        style={{
          width: "200",
        }}
      >
        Invoicing history
      </div>

      {isMobile && (
        <div className="billing_calendar_selector">
          <div className="billing_calendar">
            <div
              onClick={() => {
                setPreviousCalendar(
                  moment(previosCalendar).subtract(1, "months")._d
                );
              }}
              className="sign"
              style={{
                cursor: "pointer",
              }}
            >
              <img src={LessSignIcon} />
            </div>
            <div>
              <div
                style={{
                  fontSize: 14,
                  color: "#373737",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={CalendarIcon} />
                </div>

                <div style={{ marginLeft: 10, marginTop: 3 }}>
                  {/* {moment(previosCalendar).format("DD MMMM YYYY")} */}
                  {moment(previosCalendar)
                    .subtract(5, "months")
                    .startOf("months")
                    .format(" MMMM YYYY")}{" "}
                  ~{" "}
                  {moment(previosCalendar)
                    .subtract(1, "months")
                    .endOf("months")
                    .format(" MMMM YYYY")}
                </div>
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  setPreviousCalendar(
                    moment(previosCalendar).add(1, "months")._d
                  );
                }}
                className="sign"
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={GreaterSignIcon} />
              </div>
            </div>
          </div>
          {/* <DatePicker
          onChange={onDateRangeChosen}
          customInput={<CustomCalendar />}
          selected={previosCalendar}
          disabled={previousDate !== "previous_day" && true}
        /> */}
        </div>
      )}

      <div style={{ height: 180, fontFamily: "Chivo" }}>
        <ResponsiveContainer width="100%" height="120%">
          <BarChart
            width={500}
            height={300}
            data={monthNames}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            // barCategoryGap={"78%"}
            barGap={15}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="label"
              dx={-7}
              interval={0}
              angle={isDesktop ? 0 : -45}
              onClick={(e) => {
                let findMonth = props.billing.invoices.find((m) => {
                  return (
                    moment(m.timestamp, "YYYY-MM-DD").format("MMM YYYY") ==
                    moment(e.value, "MMM YYYY").format("MMM YYYY")
                  );
                });
                console.log("e", e);
                console.log("findMonth",  findMonth);
                downloadPdf(
                  findMonth.invoice_file,
                  moment(e).format("DD MM YYYY")
                );
                props.setPdfFileModal(true);
              }}
            />
            <YAxis
              tickFormatter={formatter}
              tick={{ fontSize: 13 }}
              // domain={[0, `${Math.floor("dataMax")}`]}
              domain={[0, (dataMax) => Math.floor(dataMax)]}
              interval={0}
              tickCount={6}
              // allowDataOverflow={true}
            />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <Bar
              dataKey="pv"
              stackId="b"
              fill="#BFEDC3"
              barSize={10}
              radius={[10, 10, 0, 0]}
              onMouseOver={() => (tooltip = "pv")}
            />
            <Bar
              dataKey="uv"
              stackId="a"
              fill="#11C126"
              barSize={10}
              radius={[10, 10, 0, 0]}
              onMouseOver={() => (tooltip = "uv")}
            />
          </BarChart>
        </ResponsiveContainer>

        {/* <Bar options={options} data={data(props.billing.invoices)} /> */}

        {/* <div className="custom_label">
          {label.map((_, index) => {
            return <div key={index}>{_}</div>;
          })}
        </div> */}
        {/* {console.log("billing", props.billing)} */}
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  billing: state.billing,
});
const mapDispatchToProps = (dispatch) => ({
  getBillingInvoice: (isMobile, from_date, to_date) =>
    dispatch(getBillingInvoice(isMobile, from_date, to_date)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderChart);
