import React from "react";
import DownloadIcon from "../../assets/images/billing/download.svg";
import { connect } from "react-redux";
import api from "../../config/api";
import config from "../../config/config";
import { ReactComponent as EditIcon } from "../../assets/images/billing/edit.svg";
import { downloadPdfInvoice } from "../../store/actions/billingAction";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/billing/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/images/billing/arrow_up.svg";

const MobileTable = React.memo((props) => {
  const { setPdfFileModal, sorting, setSorting } = props;
  const { GET_TOKEN } = config.api_endpoint;
  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const sortingFunction = (a, b) => {
    if (sorting.field == "month") {
      if (sorting.isDesc) {
        return (
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
      } else {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      }
    }
    if (sorting.field == "number") {
      if (sorting.isDesc) {
        return b.invoice_no.normalize().localeCompare(a.invoice_no.normalize());
      } else {
        return a.invoice_no.normalize().localeCompare(b.invoice_no.normalize());
      }
    }

    if (sorting.field == "amount") {
      if (sorting.isDesc) {
        return b.amount - a.amount;
      } else {
        return a.amount - b.amount;
      }
    }
  };
  const sortingHandle = (fieldName) => {
    if (sorting.field == fieldName) {
      setSorting({
        field: fieldName,
        isDesc: !sorting.isDesc,
      });
    } else {
      setSorting({
        field: fieldName,
        isDesc: true,
      });
    }
  };

  const downloadPdf = (url, name) => {
    // api.get(GET_TOKEN).then((res) => {
    let imgToken = localStorage.getItem("imgToken");
    let combineLink = url + imgToken;

    props.setPdfFile({
      url: combineLink,
      filename: name,
    });
    // });
  };

  const shouldEditInvoice =
    props.AuthDetail &&
    props.AuthDetail.permissions.length > 0 &&
    props.AuthDetail.permissions.find((p) => p === "change_invoice");

  const showNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="mobile_billing_container_table">
      <div className={`mobile_billing_header ${theme}`}>
        Invoice month{" "}
        <span
          style={{ marginLeft: 15, cursor: "pointer" }}
          onClick={() => sortingHandle("month")}
        >
          {sorting.field == "month" && sorting.isDesc ? (
            <ArrowUpIcon stroke={theme == "light" ? "grey" : "#fff"} />
          ) : (
            <ArrowDownIcon stroke={theme == "light" ? "grey" : "#fff"} />
          )}
        </span>
      </div>
      <div className={`mobile_billing_header ${theme}`}>
        Invoice num{" "}
        <span
          style={{ marginLeft: 30, cursor: "pointer" }}
          onClick={() => sortingHandle("number")}
        >
          {sorting.field == "number" && sorting.isDesc ? (
            <ArrowUpIcon stroke={theme == "light" ? "grey" : "#fff"} />
          ) : (
            <ArrowDownIcon stroke={theme == "light" ? "grey" : "#fff"} />
          )}
        </span>
      </div>
      <div className={`mobile_billing_header ${theme}`}>
        Amount{" "}
        {/* {props.billing.invoices &&
        props.billing.invoices[0] &&
        props.billing.invoices[0].currency != "-"
          ? props.billing.invoices[0].currency
          : "SGD"}{" "} */}
        <span
          style={{ marginLeft: 23, cursor: "pointer" }}
          onClick={() => sortingHandle("amount")}
        >
          {sorting.field == "amount" && sorting.isDesc ? (
            <ArrowUpIcon stroke={theme == "light" ? "grey" : "#fff"} />
          ) : (
            <ArrowDownIcon stroke={theme == "light" ? "grey" : "#fff"} />
          )}
        </span>
      </div>
      {props.billing.invoices.sort(sortingFunction).map((_) => {
        return (
          <div className="mobile_billing__table_card">
            <div className="mt_card">
              <div
                style={{
                  fontSize: 18,
                }}
              >
                {_.timestamp_fmt}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ marginRight: 20 }}>
                <div style={{ fontWeight: "bold" }}>Invoice num</div>
                <div>{_.invoice_no}</div>
              </div>
              <div>
                <div style={{ fontWeight: "bold" }}>
                  Amount{" "}
                  {props.billing.invoices &&
                    props.billing.invoices[0] &&
                    props.billing.invoices[0].currency &&
                    props.billing.invoices[0].currency}
                </div>
                <div>
                  {" "}
                  {/* {_.amount} */}
                  {_.amount == "-"
                    ? _.amount
                    : `${showNumberWithCommas(Number(_.amount).toFixed(2).toLocaleString())}`}
                </div>
              </div>
            </div>
            <div>
              {shouldEditInvoice && (
                <EditIcon
                  fill={theme === "light" ? "#000" : "#fff"}
                  width={18}
                  onClick={() => {
                    props.getDetailValue(_);
                    props.handleShowUpdate();
                  }}
                />
              )}
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (_.invoice_file == null) {
                  return alert("PDF file doesn't exist");
                }
                downloadPdf(_.invoice_file, _.timestamp_fmt);
                setPdfFileModal(true);
              }}
            >
              View
            </div>
            <div style={{ width: "100%", marginTop: 10 }}>
              <button
                className="btn btn-dark d-flex justify-content-center align-items-center"
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  if (_.invoice_file == null) {
                    return alert("PDF file doesn't exist");
                  }
                  props.downloadPdfInvoice(_.invoice_file);
                }}
              >
                <div style={{ marginRight: 5 }}>
                  <img src={DownloadIcon} />
                </div>
                <div>Download</div>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
});
const mapStateToProps = (state) => ({
  billing: state.billing,
  AuthDetail: state.loginUserDetail.userDetail,
});
const mapStateToDispatch = (dispatch) => ({
  downloadPdfInvoice: (url) => dispatch(downloadPdfInvoice(url)),
});
export default connect(mapStateToProps, mapStateToDispatch)(MobileTable);
