import React, { useEffect, useState } from "react";
import {
  Modal,
  Table,
  Button,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import HeaderChart from "./HeaderChart/HeaderChart";
import MailIcon from "../../assets/images/billing/mail.svg";
import PhoneIcon from "../../assets/images/billing/phone.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/billing/download.svg";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  getBillingInvoice,
  downloadPdfInvoice,
} from "../../store/actions/billingAction";
import { connect } from "react-redux";
import config from "../../config/config";
import { useHistory } from "react-router-dom";
import { useFormik, Field } from "formik";

import "./Billing.css";
import PdfViewer from "../../components/PdfViewer";
import DesktopTable from "./DesktopTable";
import { useMediaQuery } from "react-responsive";
import MobileTable from "./MobileTable";
import api from "../../config/api";
import moment from "moment";

const Index = (props) => {
  const [pdfFileModal, setPdfFileModal] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const isTablet = useMediaQuery({ maxWidth: 930 });
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState({
    url: "",
    fileame: "",
  });
  const [pdfZoom, setPdfZoom] = useState(1);
  const [invoiceMonth, setInvoiceMonth] = useState(new Date());
  const [updateinvoiceMonth, setUpdateInvoiceMonth] = useState(new Date());
  const [showForm, setShowForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [previosCalendar, setPreviousCalendar] = useState(new Date());

  const { GET_TOKEN, INVOICE } = config.api_endpoint;
  const history = useHistory();
  const [sorting, setSorting] = useState({
    isDesc: true,
    field: "month",
  });
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const getCurrentSite = config.getCurrentSite;

  const downloadFile = (url, filename) => {
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };

  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const prevHandle = () => {
    if (pageNumber <= 1) {
      setPageNumber(1);
    } else {
      setPageNumber((prev) => prev - 1);
    }
  };

  const nextHandle = () => {
    if (pageNumber >= numPages) {
      setPageNumber(numPages);
    } else {
      setPageNumber((prev) => prev + 1);
    }
  };

  const modalView = () => {
    return (
      <div className="pdf-container-view">
        <div
          className="modal_view_overlay"
          // onClick={(e) => {
          //   e.stopPropagation();
          //   setPdfFileModal(false);
          // }}
        >
          <div
            className="modal_view_overlay"
            style={{ zIndex: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              setPdfFileModal(false);
            }}
          ></div>
          <div className="modal_header">
            <div className="move_fun">
              <div onClick={prevHandle} className="pdf-button">
                {"<"}
              </div>

              <div className="pdf-currentPageNumber">
                {pageNumber} / {numPages}
              </div>

              <div onClick={nextHandle} className="pdf-button">
                {" "}
                {">"}
              </div>
            </div>
            <div className="right-side-pdf-header">
              <div
                onClick={() => {
                  setPdfZoom((prev) => prev + 1);
                }}
                style={{
                  marginRight: 25,
                  fontSize: 30,
                }}
              >
                +
              </div>
              <div
                onClick={() => {
                  setPdfZoom((prev) => (prev === 1 ? 1 : prev - 1));
                }}
                style={{
                  fontSize: 30,
                }}
              >
                -
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => downloadFile(pdfFile.url, pdfFile.filename)}
                  style={{
                    marginRight: 25,
                    marginLeft: 25,
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Download
                  {/* <DownloadIcon
                    style={{
                      width: 30,
                      height: 21,
                      marginTop: -5,
                    }}
                  /> */}
                </div>
                <div onClick={() => setPdfFileModal(false)}>X</div>
              </div>
            </div>
          </div>

          <PdfViewer
            setPdfFileModal={setPdfFileModal}
            pdfFile={pdfFile}
            numPages={numPages}
            setNumPages={setNumPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pdfZoom={pdfZoom}
          />
        </div>
      </div>
    );
  };

  const handleClose = () => setShowForm(false);
  const handleShow = () => setShowForm(true);

  const formValidationSchema = Yup.object({
    // invoice_no: Yup.string().required("Required Field."),
    invoice_file: Yup.mixed()
      // .required("Required Field.")
      .test("fileFormat", "Allow only PDF file.", (value) => {
        if (value.name) {
            return value && value.type === "application/pdf";
        }
        return true;
      }),
    amount: Yup.string()
      // .required("Required Field.")
      .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "Must be only digits"),
  });
  const createFormformik = useFormik({
    initialValues: {
      invoice_no: "",
      invoice_file: {},
      amount: 0,
    },
    onSubmit: (values) => {
      let formData = new FormData();

      formData.append("invoice_no", values.invoice_no);
      if (values.invoice_file.name) {
        formData.append("invoice_file", values.invoice_file);
      }
      else {
        formData.append("invoice_file", "");
      }

      formData.append("amount", values.amount);
      formData.append("timestamp", moment(invoiceMonth).format("YYYY-MM-DD"));
      formData.append(
        "site_id",
        getCurrentSite()
      );
      api
        .post(INVOICE, formData, props.CSRFToken)
        .then((res) => {
          setShowForm(false);
          props.getBillingInvoice(isMobile, null, null);
          setPreviousCalendar(new Date());
          createFormformik.resetForm();
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    validationSchema: formValidationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  const modalForm = () => {
    return (
      <Modal
        show={showForm}
        onHide={() => {
          createFormformik.resetForm();
          handleClose();
        }}
        className={`modalForm ${theme}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create an invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
            onSubmit={() => {
              createFormformik.resetForm();
              createFormformik.handleSubmit();
            }}
          >
            <Form.Label>Invoice No *</Form.Label>
            <Form.Control
              type="text"
              name="invoice_no"
              onChange={createFormformik.handleChange}
              value={createFormformik.values.invoice_no}
              onBlur={createFormformik.handleBlur}
            />
            {createFormformik.touched.invoice_no &&
              createFormformik.errors.invoice_no && (
                <Form.Text style={{ color: "red" }}>
                  {createFormformik.errors.invoice_no}
                </Form.Text>
              )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Amount*</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              onChange={createFormformik.handleChange}
              value={createFormformik.values.amount}
              onBlur={createFormformik.handleBlur}
            />
            {createFormformik.touched.amount &&
              createFormformik.errors.amount && (
                <Form.Text style={{ color: "red" }}>
                  {createFormformik.errors.amount}
                </Form.Text>
              )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Invoice Month *</Form.Label>
            <DatePicker
              dateFormat="MMMM / yyyy"
              showMonthYearPicker
              name="invoice_month"
              showFullMonthYearPicker
              className="create_modal_date_picker"
              selected={invoiceMonth}
              onChange={(date) => {
                setInvoiceMonth(date);
              }}
              onChangeRaw={(e) => e.preventDefault()}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Attachment upload (PDF file)*</Form.Label>
            <div>
              {createFormformik.values.invoice_file &&
                createFormformik.values.invoice_file.name && (
                  <div className="modal_attach_input_file_name">
                    {createFormformik.values.invoice_file.name}
                  </div>
                )}

              <div className="modal_attach_input_file">
                Upload PDF file
                <input
                  type="file"
                  name="invoice_file"
                  accpet=".pdf"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      createFormformik.setFieldValue(
                        "invoice_file",
                        e.target.files[0]
                      );
                      createFormformik.setFieldValue("has_file", true);
                    } else {
                      createFormformik.setFieldValue("has_file", false);
                    }
                  }}
                  onBlur={createFormformik.handleBlur}
                />
              </div>
            </div>
            {/* <Form.Control
              type="file"
              accpet=".pdf"
              name="invoice_file"
              onChange={(e) => {
                createFormformik.setFieldValue(
                  "invoice_file",
                  e.target.files[0]
                );
              }}
              // value={createFormformik.values.invoice_file.name}
              onBlur={createFormformik.handleBlur}
            /> */}

            {createFormformik.touched.invoice_file &&
              createFormformik.errors.invoice_file && (
                <Form.Text style={{ color: "red" }}>
                  {createFormformik.errors.invoice_file}
                </Form.Text>
              )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              createFormformik.handleSubmit();
            }}
          >
            Save
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              createFormformik.resetForm();
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleCloseUpdate = () => setShowUpdateForm(false);
  const handleShowUpdate = () => setShowUpdateForm(true);

  const updateFormValidationSchema = Yup.object({
    // invoice_no: Yup.string().required("Required Field."),
    invoice_file: Yup.mixed().when(["has_file", "is_url_file"], {
      is: (has_file, is_url_file) => has_file && !is_url_file,
      then: Yup.mixed().test("fileFormat", "Allow only PDF file.", (value) => {
        return value && value.type === "application/pdf";
      }),
    }),
    amount: Yup.string()
      // .required("Required Field.")
      .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "Must be only digits"),
  });

  const updateFormformik = useFormik({
    initialValues: {
      invoice_no: "",
      invoice_file: "",
      timestamp: "",
      has_file: false,
      is_url_file: false,
      amount: 0,
      site_id: "",
    },
    onSubmit: (values) => {
      if (values.invoice_file == null) {
        values.invoice_file = "";
      }
      if (values.invoice_no == "-") {
        values.invoice_no = "";
      }

      let formData = new FormData();
      formData.append("invoice_no", values.invoice_no);
      formData.append("invoice_file", values.invoice_file);
      formData.append(
        "site_id",
        getCurrentSite()
      );
      formData.append("amount", values.amount);
      formData.append(
        "timestamp",
        moment(updateinvoiceMonth).format("YYYY-MM-DD")
      );
      api
        .post(INVOICE, formData, props.CSRFToken)
        .then((res) => {
          console.log("res", res);
          setShowUpdateForm(false);
          props.getBillingInvoice(isMobile, null, null);
          setPreviousCalendar(new Date());
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    validationSchema: updateFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  const getDetailValue = (value) => {
    if (value) {
      setSelectId(value.id);
      updateFormformik.setFieldValue("invoice_no", value.invoice_no);
      updateFormformik.setFieldValue("invoice_file", value.invoice_file);
      updateFormformik.setFieldValue("site_id", value.site_id);
      // updateFormformik.setFieldValue("timestamp", value.timestamp);
      setUpdateInvoiceMonth(new Date(value.timestamp));
      updateFormformik.setFieldValue(
        "amount",
        value.amount == "-" ? 0 : value.amount
      );
      updateFormformik.setFieldValue("is_url_file", value.invoice_file && true);
    }
  };
  const modalUpdateForm = () => {
    return (
      <Modal
        show={showUpdateForm}
        onHide={() => {
          updateFormformik.resetForm();
          handleCloseUpdate();
        }}
        className={`modalForm ${theme}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update an invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
            onSubmit={updateFormformik.handleSubmit}
          >
            <Form.Label>Invoice No *</Form.Label>
            <Form.Control
              type="text"
              name="invoice_no"
              onChange={updateFormformik.handleChange}
              value={updateFormformik.values.invoice_no}
              onBlur={updateFormformik.handleBlur}
              // disabled
            />
            {updateFormformik.touched.invoice_no &&
              updateFormformik.errors.invoice_no && (
                <Form.Text style={{ color: "red" }}>
                  {updateFormformik.errors.invoice_no}
                </Form.Text>
              )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Amount*</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              onChange={updateFormformik.handleChange}
              value={updateFormformik.values.amount}
              onBlur={updateFormformik.handleBlur}
            />
            {updateFormformik.touched.amount &&
              updateFormformik.errors.amount && (
                <Form.Text style={{ color: "red" }}>
                  {updateFormformik.errors.amount}
                </Form.Text>
              )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Invoice Month *</Form.Label>
            <DatePicker
              dateFormat="MMMM / yyyy"
              showMonthYearPicker
              name="invoice_month"
              showFullMonthYearPicker
              className="create_modal_date_picker"
              selected={updateinvoiceMonth}
              onChange={(date) => {
                setUpdateInvoiceMonth(date);
              }}
              onChangeRaw={(e) => e.preventDefault()}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Attachment upload (PDF file) *</Form.Label>
            <div>
              {(updateFormformik.values.has_file && !updateFormformik.values.is_url_file) ? (
                updateFormformik.values.invoice_file &&
                updateFormformik.values.invoice_file.name && (
                  <div className="modal_attach_input_file_name">
                    {updateFormformik.values.invoice_file.name}
                  </div>
                )
              ) : updateFormformik.values.is_url_file ? (
                <div className="modal_attach_input_file_name">
                  {updateFormformik.values.invoice_file}
                </div>
              ) : null}

              <div className="modal_attach_input_file">
                Upload PDF file
                <input
                  type="file"
                  name="invoice_file"
                  accpet=".pdf"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      updateFormformik.setFieldValue(
                        "invoice_file",
                        e.target.files[0]
                      );
                      updateFormformik.setFieldValue("has_file", true);
                    } else {
                      updateFormformik.setFieldValue("has_file", false);
                    }
                  }}
                  onBlur={updateFormformik.handleBlur}
                />
              </div>
            </div>
            {/* <Form.Control
              type="file"
              accpet=".pdf"
              name="invoice_file"
              onChange={(e) => {
                if (e.target.files[0]) {
                  updateFormformik.setFieldValue(
                    "invoice_file",
                    e.target.files[0]
                  );
                  updateFormformik.setFieldValue("has_file", true);
                } else {
                  updateFormformik.setFieldValue("has_file", false);
                }
              }}
              onBlur={updateFormformik.handleBlur}
            /> */}

            {updateFormformik.touched.invoice_file &&
              updateFormformik.errors.invoice_file && (
                <Form.Text style={{ color: "red" }}>
                  {updateFormformik.errors.invoice_file}
                </Form.Text>
              )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={updateFormformik.handleSubmit}>
            Update
          </Button>
          <Button variant="danger" onClick={handleDeleteFunction}>
            Delete
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              updateFormformik.resetForm();
              handleCloseUpdate();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleCloseDelete = () => setShowDeleteForm(false);
  const handleShowDelete = (id) => {
    setSelectId(id);
    setShowDeleteForm(true);
  };

  const handleDeleteFunction = () => {
    let formData = new FormData();
    formData.append("id", selectId);
    console.log("selectId", selectId);
    api
      .remove(INVOICE, formData, props.CSRFToken)
      .then((res) => {
        console.log("res", res);
        setShowUpdateForm(false);
        props.getBillingInvoice(isMobile, null, null);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const modalDeleteForm = () => {
    return (
      <Modal
        show={showDeleteForm}
        onHide={() => {
          handleCloseDelete();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete an invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteFunction}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const shouldCreateInvoice =
    props.AuthDetail &&
    props.AuthDetail.permissions.length > 0 &&
    props.AuthDetail.permissions.find((p) => p === "add_invoice");
  

  const getSiteCurrency = () => {
    try {
      return props.AuthDetail.company.filter(x => x.name === getCurrentSite())[0].currency
    } catch (error) {
      return "";
    }
  }

  return (
    <div className="mobile_bill">
      {modalForm()}
      {modalUpdateForm()}
      {modalDeleteForm()}
      {props.billing.loading || loading ? (
        <Modal
          show={props.billing.loading || loading}
          data-backdrop="static"
          data-keyboard="false"
          className="loader"
        >
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      ) : null}

      {pdfFileModal && modalView()}
      <HeaderChart
        setPdfFileModal={setPdfFileModal}
        setPdfFile={setPdfFile}
        setLoading={setLoading}
        previosCalendar={previosCalendar}
        setPreviousCalendar={setPreviousCalendar}
      />
      <div className={`billing_table ${theme}`}>
        <div className="billing_table_c">
          <div className="invoice_table_container_header">
            <div
              className="title prev_invoices_title"
              style={{
                width: "200",
                color: theme == "dark" && "#fff",
              }}
            >
              Previous invoices
            </div>
            <div className="create_invoice_button">
              {shouldCreateInvoice && (
                <button
                  className="btn btn-dark d-flex justify-content-around align-items-center"
                  onClick={handleShow}
                >
                  <div>Create an invoice</div>
                </button>
              )}
            </div>
          </div>

          {isTablet ? (
            <MobileTable
              setPdfFileModal={setPdfFileModal}
              setPdfFile={setPdfFile}
              handleShowUpdate={handleShowUpdate}
              handleShowDelete={handleShowDelete}
              getDetailValue={getDetailValue}
              sorting={sorting}
              setSorting={setSorting}
            />
          ) : (
            <DesktopTable
              setPdfFileModal={setPdfFileModal}
              setPdfFile={setPdfFile}
              handleShowUpdate={handleShowUpdate}
              handleShowDelete={handleShowDelete}
              getDetailValue={getDetailValue}
              sorting={sorting}
              setSorting={setSorting}
              currency={getSiteCurrency()}
            />
          )}
        </div>
      </div>
      <div
        style={{ marginTop: 15, marginBottom: 15, paddingLeft: isMobile && 25 }}
      >
        For any financial queries, please contact your Customer Success Manager.
      </div>
      <div className="billing_footer" style={{ paddingLeft: isMobile && 25 }}>
        <div>
          <b>Ms Tracy Ong</b>
        </div>

        <div>
          <a href="mailto:tracy.ong@kaer.com" target="_parent">
            tracy.ong@kaer.com
          </a>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ marginRight: 20, width: isMobile && 150 }}>
            +65 9123 4567
          </div>
          <div
            style={{
              background: "#005DAA",
              width: 30,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              cursor: "pointer",
            }}
          >
            <img src={MailIcon} />
          </div>
          <div
            style={{
              background: "#005DAA",
              width: 30,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              marginLeft: 10,
              cursor: "pointer",
            }}
          >
            <img src={PhoneIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  billing: state.billing,
  CSRFToken: state.loginUserDetail.csrf,
  AuthDetail: state.loginUserDetail.userDetail,
});

const mapDispatchToProps = (dispatch) => ({
  getBillingInvoice: (isMobile, from_date, to_date) =>
    dispatch(getBillingInvoice(isMobile, from_date, to_date)),
  downloadPdfInvoice: (url) => dispatch(downloadPdfInvoice(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
