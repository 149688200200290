import React, { useState, useEffect } from "react";
import { dataSpace, optionsSpace, options } from "./space_chart_setting";
import { Line } from "react-chartjs-2";
import "./Chart.css";
import { connect } from "react-redux";
import {
  Modal,
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as HomeIcon } from "../../../assets/images/Data/Home.svg";
import { ReactComponent as BoxIcon } from "../../../assets/images/Data/Box.svg";
import { ReactComponent as FloorIcon } from "../../../assets/images/Data/Floor.svg";
import SpaceIcon from "../../../assets/images/live/space.svg";
import SpaceDarkIcon from "../../../assets/images/live/space-dark.svg";
import LayersIcon from "../../../assets/images/live/layers.svg";
import LayersDarkIcon from "../../../assets/images/live/layers-dark.svg";
import { ReactComponent as CubeIcon } from "../../../assets/images/live/cube.svg";
import { ReactComponent as CubeDarkIcon } from "../../../assets/images/live/cube-dark.svg";

const footTextStyle = {
  width: "fit-content",
  background: "#ecebeb",
  display: "flex",
  padding: 5,
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 4,
  border: "1px solid #F8F8F8",
  fontSize: 14,
};

const theme = localStorage.getItem("theme");

const spaceFloors = ["All", "1"];

function SpaceTempatureChart(props) {
  const { title, data_count } = props;
  const isMobile = useMediaQuery({ maxWidth: 765 });
  const isTablet = useMediaQuery({ maxWidth: 991 });
  const [count, setCount] = useState({});
  const [tempature, setTempature] = useState([]);
  const [humidity, setHumidity] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState("New Sensor 1");
  const [hoverSelectedBlock, setHoverSelectdBlock] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [filter, setFilter] = useState("All");
  const [spaceBlocks, setSpaceBlocks] = useState([]);
  const [buildingList, setBuilingList] = useState([]);
  const [selectBuilding, setSelectBuilding] = useState("");
  const [selectFloorNumber, setSelectFloorNumber] = useState("");
  const [filterFloorList, setFilterFloorList] = useState([]);
  const [allFloorList, setAllFloorList] = useState([]);
  const [optionsForDialogcontent, setOptionsForDialogcontent] = useState([]);
  const [allBlockList, setAllBlockList] = useState([]);
  const [label, setLabel] = useState([]);

  const maxWidth765 = useMediaQuery({ maxWidth: 765 });
  const maxWidth500 = useMediaQuery({ maxWidth: 500 });

  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const spaceHeaderStyleForDesktop = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  useEffect(() => {
    console.log("useEffect data_count, selectedBlock: ", data_count);
    console.log("useEffect data_count, selectedBlock: ", selectedBlock);
    if (
      data_count &&
      data_count.graph &&
      data_count.graph.space_data &&
      typeof data_count.graph.space_data !== null &&
      typeof data_count.graph.space_data !== undefined
      // && data_count.graph.space_data[selectedBlock]
    ) {
      let changeArray = Object.keys(data_count.graph.space_data);
      if (typeof changeArray == "object" && changeArray.length > 0) {
        let search = changeArray.find(
          (c) => c.toLowerCase() == selectedBlock.toLowerCase()
        );

        let datasT = [];
        let datasH = [];
        let labels = [];

        if (search) {
          let tempaturesData = Object.entries(
            data_count.graph.space_data[search].temperature
          ).sort(([key, value], [key2, value2]) => key - key2);

          let humidiyData = Object.entries(
            data_count.graph.space_data[search].humidity
          ).sort(([key, value], [key2, value2]) => key - key2);

          tempaturesData.map(([key, value]) => {
            labels.push(key);
            datasT.push(value);
          });
          humidiyData.map(([key, value]) => {
            datasH.push(value);
          });
          //   let sortTempature = Object.entries(
          //     data_count.graph.space_data[search]
          //   )
          //     .sort(([key, value], [key2, value2]) => key - key2)
          //     .map(([key, value]) => {
          //       Object.entries(value).map(([k, v]) => {
          //         labels.push(k);
          //         if (key === "temperature") {
          //           datasT.push(v);
          //         } else {
          //           datasH.push(v);
          //         }
          //       });
          //     });
        }
        setLabel(labels.sort((a, b) => a - b));
        setTempature(datasT);
        setHumidity(datasH);
      }
    }
  }, [data_count, selectedBlock]);

  useEffect(() => {
    console.log("useEffect data_count: ", data_count);
    console.log("useEffect data_count: ", selectedBlock);
    if (
      data_count &&
      data_count.graph &&
      data_count.graph.space_list &&
      data_count.graph.space_list.length > 0
    ) {
      let checkIfExists = false;
      if (localStorage.getItem("selected_blocks_datapage")) {
        data_count.graph.space_list.forEach((element) => {
          if (
            element.key === localStorage.getItem("selected_blocks_datapage")
          ) {
            checkIfExists = true;
          }
        });
      }
      if (checkIfExists) {
        setSelectedBlock(localStorage.getItem("selected_blocks_datapage"));
      } else {
        setSelectedBlock(data_count.graph.space_list[0].key);
      }
      setBuilingList(data_count.graph.building_list);

      let getTypeList = Object.keys(
        data_count.graph.room_map[data_count.graph.building_list[0]]
      );

      setOptionsForDialogcontent(["All", ...getTypeList]);

      // Left side Type List
      // setFilter(getTypeList[0]);

      // header e.g Main
      setSelectBuilding(data_count.graph.building_list[0]);

      // Get FLoor List depends on Left Side Type Select value
      const getSpecificFloorList =
        data_count.graph.room_map[data_count.graph.building_list[0]];
      setFilterFloorList([
        "All",
        ...Object.keys(getSpecificFloorList[getTypeList[0]]),
      ]);

      // Get All Floor List
      setAllFloorList(["All", ...data_count.graph.floor_list]);
      // setSelectFloorNumber(
      //   Object.keys(getSpecificFloorList[getTypeList[0]])[0]
      // );
      setSelectFloorNumber("All");
      // For graph bar
      setSpaceBlocks(data_count.graph.space_list);
    }
  }, [data_count]);

  const sortRooms = (a, b) => {
    const getLevelValue = (level) => {
      if (level.startsWith("B")) {
        // Return negative values for basement levels (e.g., "B1" -> -1)
        return -parseInt(level.replace("B", ""), 10);
      }
      // Return positive values for normal levels (e.g., "L1" -> 1)
      return parseInt(level.replace("L", ""), 10);
    };
    const levelA = getLevelValue(a.level_raw);
    const levelB = getLevelValue(b.level_raw);
    if (levelA === levelB) {
      return a.name.localeCompare(b.name); // Sort by name if levels are the same
    }
    return levelA - levelB; // Sort levels, with basements coming first
  };

  useEffect(() => {
    if (
      data_count &&
      data_count.graph &&
      data_count.graph.space_list &&
      selectBuilding &&
      data_count.graph.room_map &&
      filter
    ) {
      const getTypeList = Object.keys(
        data_count.graph.room_map[selectBuilding]
      );
      setOptionsForDialogcontent(["All", ...getTypeList]);

      // _type == "filter"
      // _floor == "selectFloorNumber"
      // _cat == "selectBuilding
      // _data == data_count.graph

      var chosenData = [];
      let selectedData = data_count.graph["room_map"][selectBuilding];

      if (filter === "All") {
        if (selectFloorNumber === "All") {
          Object.keys(selectedData).forEach(function (key) {
            // console.log(key + " " + selectedData[key]);

            Object.keys(selectedData[key]).forEach(function (key2) {
              // console.log(key2 + " " + selectedData[key][key2]);

              chosenData.push(...selectedData[key][key2]);
            });
          });
        } else {
          Object.keys(selectedData).forEach(function (key) {
            if (selectFloorNumber in selectedData[key]) {
              chosenData.push(...selectedData[key][selectFloorNumber]);
            }
          });
        }
      } else {
        if (selectFloorNumber === "All") {
          if (selectedData[filter]) {
            Object.keys(selectedData[filter]).forEach(function (key2) {
              chosenData.push(...selectedData[filter][key2]);
            });
          }
        } else {
          if (selectedData[filter]) {
            if (selectFloorNumber in selectedData[filter]) {
              chosenData.push(...selectedData[filter][selectFloorNumber]);
            }
          }
        }
      }

      chosenData = chosenData.sort(sortRooms);

      setAllBlockList(chosenData);
    }
  }, [data_count, selectBuilding, filter, selectFloorNumber]);

  const NewModal = (props) => {
    return (
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header
          closeButton
          className={`${theme === "light" ? "" : "dark dark-blk"}`}
        >
          <Modal.Title>
            <div className="modal-padding-blk"></div>{" "}
            <div className="modal-title-blk">
              <span className={`${theme === "light" ? "" : "dark"}`}>
                Select
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${theme === "light" ? "" : "dark dark-blk"}`}>
          <SpaceDialogContent />
        </Modal.Body>
      </Modal>
    );
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const SpaceDialogContent = (props) => {
    return (
      <>
        <div
          className=" bulding-blocks-container"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          {/* <input
            type="radio"
            className="btn-check"
            name="btnMain"
            id="btnMain"
            autoComplete="off"
            // onClick={spaceCatClicked}
          /> */}
          {/* ${
              selectedSpaceCat === "Main block" && "active"
            } ${theme === "light" ? "" : "dark"} */}
          {buildingList.map((bulding, index) => {
            return (
              <div
                key={index}
                className={`btn add-space-type-space-temp-chart ${
                  selectBuilding === bulding && "active"
                }`}
                onClick={() => {
                  setSelectBuilding(bulding);
                  let getTypeList = Object.keys(
                    data_count.graph.room_map[bulding]
                  );

                  setFilter("All");

                  let floors = ["All"];

                  Object.keys(data_count.graph.room_map[bulding]).forEach(
                    (key1) => {
                      Object.keys(
                        data_count.graph.room_map[bulding][key1]
                      ).forEach((key2) => {
                        floors.push(key2);
                      });
                    }
                  );
                  floors.sort(
                    (a, b) =>
                      data_count.graph["floor_list"].indexOf(a) -
                      data_count.graph["floor_list"].indexOf(b)
                  );
                  floors = floors.filter(onlyUnique);
                  setAllFloorList(floors);
                }}
              >
                {bulding}
              </div>
            );
          })}
        </div>
        <hr className="d-none d-lg-block"></hr>
        {!isTablet && <br className="d-block d-lg-none"></br>}

        <br className="d-block d-lg-none"></br>
        <Row>
          <Col lg="2" md="2">
            <div
              className={`d-none d-lg-block space-type-chooser ${
                theme === "light" ? "" : "dark-blk"
              }`}
            >
              <Image
                className="img-fluid"
                src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                style={{ paddingBottom: "3px" }}
              />
              <p
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  paddingLeft: "10px",
                }}
              >
                <b>Type</b>
              </p>
              {optionsForDialogcontent.map((value, index) => {
                return (
                  <div key={index}>
                    {index !== 0 && <hr></hr>}
                    <p
                      className={`space-type-info ${
                        filter === value && "active"
                      }`}
                      onClick={() => setFilter(value)}
                    >
                      {value}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              className="d-block d-lg-none accordion accordion-flush space-type-chooser-accordion"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <Image
                      className="img-fluid"
                      src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                    />
                    <p
                      style={{
                        display: "inline-block",
                        fontSize: "15px",
                        paddingLeft: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      <b>Type</b>
                    </p>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {optionsForDialogcontent.map((value, index) => {
                      return (
                        <div key={index}>
                          {index !== 0 && <hr></hr>}
                          <p
                            className={`space-type-info ${
                              filter === value && "active"
                            }`}
                            onClick={() => setFilter(value)}
                          >
                            {value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg="10" md="10">
            <div style={{ paddingTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Image
                      className="img-fluid"
                      src={theme === "light" ? LayersIcon : LayersDarkIcon}
                      width="30px"
                    />
                  </div>

                  <div
                    style={{
                      display: "inline-block",
                      fontSize: "15px",
                      fontWeight: "400",
                      paddingLeft: "10px",
                    }}
                  >
                    <b>Floor</b>
                  </div>
                </div>
                <div
                  className="middle_line"
                  style={{ display: isTablet && "none" }}
                ></div>
                <div style={{ display: isTablet && "none" }}>
                  {/* <br className="d-block d-lg-none"></br> */}
                  <div className="paging-space-blk-data">
                    {allFloorList.map((value, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            setSelectFloorNumber(value);
                          }}
                        >
                          <span
                            className={`paging-space-page
                          ${selectFloorNumber === value && "active"}`}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    })}
                    <div
                      className="paging-space-page"
                      onClick={() => {
                        const currentIndex =
                          allFloorList.indexOf(selectFloorNumber);

                        const nextIndex =
                          (currentIndex + 1) % allFloorList.length;
                        setSelectFloorNumber(allFloorList[nextIndex]);
                      }}
                    >
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div className="paging-space-blk-mobile data-floor-list-mobile  d-lg-none">
                {allFloorList.map((value, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectFloorNumber(value)}
                    >
                      <span
                        className={`paging-space-page-mobile ${
                          selectFloorNumber === value && "active"
                        } `}
                      >
                        {value}
                      </span>
                    </div>
                  );
                })}
                <span
                  className="paging-space-page data-floor-list-next"
                  onClick={() => {
                    const currentIndex =
                      allFloorList.indexOf(selectFloorNumber);

                    const nextIndex = (currentIndex + 1) % allFloorList.length;
                    setSelectFloorNumber(allFloorList[nextIndex]);
                  }}
                >
                  >
                </span>
              </div>
            </div>
            <div>
              <Row>
                {allBlockList &&
                  allBlockList.map((_, index) => {
                    return (
                      <Col
                        md="3"
                        sm="6"
                        className="spacer col-6"
                        key={_.name}
                        onMouseOver={() => {
                          setHoverSelectdBlock(_.name);
                        }}
                        onMouseLeave={() => setHoverSelectdBlock("")}
                      >
                        <div
                          className={`rounded space-floor-blk  te`}
                          style={{
                            position: "relative",
                          }}
                          onClick={() => {
                            setSelectedBlock(_.name);
                            localStorage.setItem(
                              "selected_blocks_datapage",
                              _.name
                            );
                            setModalShow(false);
                          }}
                          data-tooltip={_.name}
                        >
                          {theme === "light" && hoverSelectedBlock === _ ? (
                            <CubeDarkIcon
                              style={{
                                marginLeft: 10,
                              }}
                            />
                          ) : theme === "light" &&
                            hoverSelectedBlock !== _.name ? (
                            <CubeIcon
                              style={{
                                marginLeft: 10,
                              }}
                            />
                          ) : (
                            <CubeDarkIcon
                              style={{
                                marginLeft: 10,
                              }}
                            />
                          )}

                          <p
                            className="space-blk-level"
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              lineHeight: "0.5em",
                            }}
                          >
                            <b>{_.level}</b>
                          </p>
                          <p
                            className="space-blk-name"
                            style={{ lineHeight: "1em", textAlign: "left" }}
                          >
                            {_.name}
                          </p>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div
      className="charts_container_mobile"
      style={{
        margin: "auto",
      }}
    >
      {NewModal()}
      {/* <DialogContent
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        filter={filter}
        setFilter={setFilter}
        optionsList={optionsList}
        selected={selected}
        setSelected={setSelected}
      /> */}
      <div
        className={`data_big_chart_container ${theme} ${
          !props.shouldDisplaySpaceChart && "hide"
        }`}
      >
        <div
          className="space_container"
          style={!isMobile ? spaceHeaderStyleForDesktop : {}}
        >
          <div className="title">{title}</div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 13,
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              {selectedBlock}
            </div>
            <div
              style={{
                fontSize: 13,
                background: "#005DAA",
                color: "#fff",
                width: 100,
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 4,
                marginLeft: 5,
                cursor: "pointer",
              }}
              onClick={() => setModalShow(true)}
            >
              Change
            </div>
          </div>
        </div>
        <div
          style={{
            height: 300,
          }}
        >
          <Line
            options={options(maxWidth765, maxWidth500, label)}
            data={dataSpace(tempature, humidity, label)}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              ...footTextStyle,
              marginRight: 8,
              backgroundColor: theme == "dark" ? " #000" : "#fff",
            }}
          >
            <div
              style={{
                width: 20,
                height: 3,
                borderRadius: 10,
                background: "#005DAA",
                marginRight: 5,
              }}
            ></div>
            Temperature
          </div>
          <div
            style={{
              ...footTextStyle,
              backgroundColor: theme == "dark" ? " #000" : "#fff",
            }}
          >
            <div
              style={{
                width: 20,
                height: 3,
                borderRadius: 10,
                background: "#00A6EF",
                marginRight: 5,
              }}
            ></div>
            RH
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  data_count: state.data.data_count,
});
export default connect(mapStateToProps, null)(SpaceTempatureChart);
