import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/billing/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/images/billing/arrow_up.svg";
import DownloadIcon from "../../assets/images/billing/download.svg";
import { ReactComponent as EditIcon } from "../../assets/images/billing/edit.svg";
import api from "../../config/api";
import config from "../../config/config";
import { downloadPdfInvoice } from "../../store/actions/billingAction";

const DesktopTable = React.memo((props) => {
  const { GET_TOKEN } = config.api_endpoint;

  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const { setPdfFileModal, sorting, setSorting } = props;

  const sortingFunction = (a, b) => {
    if (sorting.field == "month") {
      if (sorting.isDesc) {
        return (
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
      } else {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      }
    }
    if (sorting.field == "number") {
      if (sorting.isDesc) {
        return b.invoice_no.normalize().localeCompare(a.invoice_no.normalize());
      } else {
        return a.invoice_no.normalize().localeCompare(b.invoice_no.normalize());
      }
    }

    if (sorting.field == "amount") {
      if (sorting.isDesc) {
        return b.amount - a.amount;
      } else {
        return a.amount - b.amount;
      }
    }
  };
  const sortingHandle = (fieldName) => {
    if (sorting.field == fieldName) {
      setSorting({
        field: fieldName,
        isDesc: !sorting.isDesc,
      });
    } else {
      setSorting({
        field: fieldName,
        isDesc: true,
      });
    }
  };

  const downloadPdf = (url, name) => {
    // api.get(GET_TOKEN).then((res) => {
    let imgToken = localStorage.getItem("imgToken");
    let combineLink = url + imgToken;

    props.setPdfFile({
      url: combineLink,
      filename: name,
    });
    // });
  };

  const shouldEditInvoice =
    props.AuthDetail &&
    props.AuthDetail.permissions.length > 0 &&
    props.AuthDetail.permissions.find((p) => p === "change_invoice");

  const showNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Table bordered hover borderless={true} bordered={false} responsive="xl">
      <thead>
        <tr>
          <th colSpan={0.5}>
            Month
            <span
              style={{ marginLeft: 15, cursor: "pointer" }}
              onClick={() => sortingHandle("month")}
            >
              {sorting.field == "month" && sorting.isDesc ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )}
            </span>
          </th>
          <th>
            Number
            <span
              style={{ marginLeft: 15, cursor: "pointer" }}
              onClick={() => sortingHandle("number")}
            >
              {sorting.field == "number" && sorting.isDesc ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )}
            </span>
          </th>
          <th>
            {props.currency ? `Amount (${props.currency})` : "Amount"}
            <span
              style={{ marginLeft: 15, cursor: "pointer" }}
              onClick={() => sortingHandle("amount")}
            >
              {sorting.field == "amount" && sorting.isDesc ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )}
            </span>
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.billing.invoices.sort(sortingFunction).map((_) => {
          return (
            <tr key={_.id || Math.random() * 1000}>
              <td className="align-middle">{_.timestamp_fmt}</td>
              <td className="align-middle">{_.invoice_no}</td>
              <td className="align-middle">
                {/* {_.amount} */}
                {_.amount == "-"
                  ? _.amount
                  : `${showNumberWithCommas(Number(_.amount).toFixed(2).toLocaleString())}`}
              </td>

              <td
                colSpan={2}
                className="d-flex align-items-center justify-content-evenly"
              >
                <div>
                  {shouldEditInvoice && (
                    <EditIcon
                      width={18}
                      fill={theme === "light" ? "#000" : "#fff"}
                      onClick={() => {
                        props.getDetailValue(_);
                        props.handleShowUpdate();
                      }}
                    />
                  )}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (_.invoice_file == null) {
                      return alert("PDF file doesn't exist");
                    }
                    downloadPdf(_.invoice_file, _.timestamp_fmt);
                    setPdfFileModal(true);
                  }}
                >
                  View
                </div>
                <button
                  className="btn btn-dark d-flex justify-content-around align-items-center"
                  onClick={() => {
                    if (_.invoice_file == null) {
                      return alert("PDF file doesn't exist");
                    }
                    props.downloadPdfInvoice(_.invoice_file);
                  }}
                >
                  <div style={{ marginRight: 5 }}>
                    <img src={DownloadIcon} />
                  </div>
                  <div>Download</div>
                </button>
                {/* <div
                  onClick={() => {
                    props.handleShowDelete(_.id);
                  }}
                >
                  Delete
                </div> */}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
});
const mapStateToProps = (state) => ({
  billing: state.billing,
  AuthDetail: state.loginUserDetail.userDetail,
});

const mapStateToDispatch = (dispatch) => ({
  downloadPdfInvoice: (url) => dispatch(downloadPdfInvoice(url)),
});

export default connect(mapStateToProps, mapStateToDispatch)(DesktopTable);
